import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Podologia = ({ wpPodologia }) => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO
        seoTitle="Podolog Kraków - gabinet podologiczny Omeda-Med"
        description="Profesjonalny gabinet podologiczny w Krakowie. W naszym centrum medycznym pracuje uznany podolog oraz specjaliści z wielu dziedzin medycyny."
      />

      <GlobalTop title="Podolog Kraków – gabinet podologiczny" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Czym zajmuje się <strong>Podolog?</strong>
              </h2>
              <p>
                Podologia zajmuje się dbałością o prawidłową kondycję stóp.
                Usługi z zakresu podologii dotyczą przeciwdziałania powstawaniu
                zmian chorobowych w obrębie stóp oraz planowania i
                przeprowadzania terapii w przypadku istniejących dolegliwości.
              </p>
              <h2 className="section--header">
                Jak przebiega konsultacja podologiczna?
              </h2>
              <p>
                Jako podolog, udzielam profesjonalnej pomocy w kwestii
                prawidłowego skracania paznokci, problemów paznokci, np.
                grzybicy, wrastanie, wkręcanie płytek paznokciowych, protetyki
                paznokcia, usuwanie modzeli, odcisków, brodawek.
              </p>
              <p>
                Udzielam pacjentom wskazówek dotyczących pielęgnacji stóp,
                pielęgnacji skóry i paznokci, profilaktyki, korekcji i
                prawidłowej ochrony zmian ortopedycznych oraz poprawy stanu
                mięśni stóp. Rozpoznaję patologiczne zmiany na stopach oraz
                przeprowadzam odpowiednie zabiegi (zalecane przez lekarza lub
                przeze mnie).
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer-podologia-image-1.png")}
                srcSet={require("../../assets/images/offer-podologia-image-1@2x.png")}
                alt="podolog Kraków"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="offer-single-services no-margin">
        <div className="container">
          <h2 className="section--header text-center">
            Usługi – gabinet podologiczny Kraków
          </h2>
          <div className="row">
            {wpPodologia.nodes.map((node, index) => (
              <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                <Link
                  to={`/oferta/podologia${node.uri}`}
                  className="offer-single-services__tile"
                  style={{
                    backgroundImage: `url(${
                      node.acfPodologia.podologiaGridIcon.sourceUrl
                        ? node.acfPodologia.podologiaGridIcon.sourceUrl
                        : ""
                    })`,
                  }}
                >
                  <h4>{node.title}</h4>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="offer-single-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <h2 className="section--header">
                W czym może pomóc Ci podologia?
              </h2>
              <p>
                Podologia zajmuje się szeregiem dolegliwości związanych ze
                stopami i paznokciami. Specjalista podolog w naszym centrum
                medycznym zajmuje się diagnostyką, a następnie proponuje
                odpowiednie leczenie chorób i dolegliwości, w tym również
                zabiegi podologiczne w obrębie stóp i paznokci. Dobry podolog
                zawsze współpracuje z lekarzami, będącymi specjalistami w swoich
                dziedzinach, tak aby zapewnić pacjentom sprawdzone metody oraz
                najlepsze możliwe postępowanie lecznicze.
              </p>
              <p>
                Do naszego gabinetu podologiczngo w Krakowie - Omeda-Med,
                zapraszamy osoby borykające się z takimi problemami czy
                dolegliwościami, jak haluks, pękające pięty, leczenie
                wrastających paznokci, grzybica stóp i paznokci, sucha skóra
                stóp czy usuwanie brodawek.
              </p>
              <p>
                Wszelkie zabiegi realizowane są z zachowaniem zasad higieny, a
                przyrządy i narzędzia poddawane są zawsze dezynfekcji i
                sterylizacji. Zapewniamy naszym pacjentom wysoką jakość
                wykonywanych usług oraz atmosferę wspierającą komfort.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        wpPodologia: allWpPodologia {
          nodes {
            uri
            id
            slug
            title
            acfPodologia {
              podologiaGridIcon {
                sourceUrl
              }
            }
          }
        }
      }
    `}
    render={data => <Podologia {...data} />}
  />
)
